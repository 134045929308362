//  points
//  👉 if you give just 'primary' anywhere, by default primary.main will be considered - that is our standard purple color, primary.main from primary object in palette below  ( same for secondary - secondary.main)

import { fontFamily } from '@mui/system';
import { useThemeContext } from '../containers/Settings/GlobalSettings/GlobalSettingsTabs/ThemeContext';

//  👉 for backgrounds for basic boxes and grids - give property like
// <Grid bgColor={theme.palette.background.light}> ..... </Grid> - this will give background of white in light mode and light black-greyish color in dark mode because see in background object in palette object below --- search for `theme.palette.background' in the src folder to see examples

// 👉 for texts - on dark backgrounds - we want white text and on white / light backgrounds we want black text - for this use `theme.palette.text.primary` ( see text object in the palette below ) -- search for `theme.palette.text.primary` in src folder to see examples

// 👉 for header navbar - give - style={{ backgroundColor: theme.palette.background.main }} commonpageheading

// 🌟 if you ever get stuck - search for 'theme.palette' in the src folder to see how I have used this feature throughout the app

// color design tokens export
export const colorTokens = {
	grey: {
		0: '#FFFFFF',
		10: '#F4F7FC', // bg color
		50: '#F0F0F0',
		100: '#E0E0E0',
		110: '#dadada',
		200: '#C2C2C2',
		300: '#A3A3A3',
		400: '#858585',
		500: '#666666',
		600: '#4D4D4D',
		650: '#2f2f2f', // dropdowns list bg color and calender bg color in dark
		700: '#24282F', // grid  boxes / cards / filter modal  background
		750: '#0000001a', // dark grey
		800: '#161B22', // changed - dark
		900: '#0D1116', // changed default page background
		950: '#060F1D', // header navbar changed
		1000: '#000000',
	},
	primary: {
		50: '#E6FBFF',
		100: '#f9f7fc',
		200: '#F2E9FF',
		300: '#a45cffbd',
		400: '#D412FF',
		500: '#9149ff',
		600: '#00A0BC',
		700: '#006B7D',
		800: '#00353F',
		900: '#001519',
	},
	others: {
		// define additional colors here and define in palette for both dark and light theme - eg check / search for - channelAndAttributeBoxesStyle
		// also see this link for examples - https://stackblitz.com/edit/react-4b6dzp?file=MyTheme.js,index.js
		10: '#00A0BC',
	},
};

// mui theme settings
export let themeSettings: any = (
	mode: string,
	primaryColorApp: any,
	secondaryColorApp: any,
	graphColorsApp: any,

	barGraphColors: any,
	trendGraphColors: any,
	areaChartColors: any,
	donutPieChartColors: any,
	funnelGraphColors: any,
	fontSizeContext: any
) => {
	const handleFontChange = (value: string = 'medium') => {
		switch (value) {
			case 'small':
				return {
					fontFamily: 'Lato',
					tablefont: {
						rowFontSize: '.875rem',
						headFontSize: '.813rem',
						cellFontSize: '.75rem',
					},
					tabPerformance: {
						fontSize: '.688rem',
					},
					sidebarfont: {
						headingFontSize: '.813rem',
						subHeadingFontSize: '.75rem',
					},
					breadCrum: {
						breadCrumFontSize: '.563rem',
					},
					barGraph: {
						titleFont: '1.1875rem',
						pieFont: '.6875rem',
						legendFont: '.625rem',
						noDataFont: '.75rem',
					},
					sourceCard: '.6563rem',
					dashboardFont: '1.0625rem',
					dialogTitle: '1.4375rem',
					comparisonDashboard: '2.3125rem',
					funnelText: '1.3125rem',
					globalAttributes: '.9375rem',
					numPercentage: '1.6875rem',
					titleDialog: '1.5625rem',
					loginFont: '7px',
					channelFont: '.7125rem',
					socialMedia: '2.1875rem',
					calendarFont: '.5625rem',
					h6: {
						fontSize: '15px',
						'@media (max-width:1500px)': {
							fontSize: '.813rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '.838rem',
						},
					},
					h5: {
						fontSize: '19px',
						fontWeight: 600,
						'@media (max-width:1500px)': {
							fontSize: '17px',
						},
						'@media (max-width:1366px)': {
							fontSize: '.813rem',
						},
					},
					h4: {
						fontSize: '25px',
						fontWeight: 600,
						'@media (max-width:1500px)': {
							fontSize: '21px',
						},
						'@media (max-width:1366px)': {
							fontSize: '17px',
						},
					},
					h3: {
						fontSize: '1.813rem',
						fontWeight: 600,
						'@media (max-width:1500px)': {
							fontSize: '1.413rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '1.225rem',
						},
					},
					h2: {
						fontSize: '42px',
						'@media (max-width:1500px)': {
							fontSize: '2.613rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '2.213rem',
						},
					},
					h1: {
						fontSize: '65px',
						'@media (max-width:1500px)': {
							fontSize: '3.813rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '3.213rem',
						},
					},
				};

			case 'medium':
				return;
			case 'large':
				return {
					fontFamily: 'Lato',
					tablefont: {
						rowFontSize: '1.25rem',
						headFontSize: '18px',
						cellFontSize: '1.125rem',
					},
					tabPerformance: {
						fontSize: '1.125rem',
					},
					sidebarfont: {
						headingFontSize: '1.188rem',
						subHeadingFontSize: '1.125rem',
					},
					breadCrum: '.938rem',
					barGraph: {
						titleFont: '1.5625rem',
						pieFont: '1.063rem',
						legendFont: '2rem',
						noDataFont: '1.125rem',
					},
					sourceCard: '1.0313rem',
					dashboardFont: '1.4375rem',
					dialogTitle: '1.75rem',
					comparisonDashboard: '2.6875rem',
					funnelText: '1.6875rem',
					globalAttributes: '1.3125rem',
					numPercentage: '2.0625rem',
					titleDialog: '1.9375rem',
					loginFont: '13px',
					channelFont: '1.0875rem',
					socialMedia: '2.5625rem',
					calendarFont: '.9375rem',
					h6: {
						fontSize: '21px',
						'@media (max-width:1500px)': {
							fontSize: '1.188rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '1.088rem',
						},
					},
					h5: {
						fontSize: '25px',
						fontWeight: 600,
						'@media (max-width:1500px)': {
							fontSize: '23px',
						},
						'@media (max-width:1366px)': {
							fontSize: '1.188rem',
						},
					},
					h4: {
						fontSize: '31px',
						fontWeight: 600,
						'@media (max-width:1500px)': {
							fontSize: '27px',
						},
						'@media (max-width:1366px)': {
							fontSize: '23px',
						},
					},
					h3: {
						fontSize: '2.188rem',
						fontWeight: 600,
						'@media (max-width:1500px)': {
							fontSize: '1.788rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '1.588rem',
						},
					},
					h2: {
						fontSize: '48px',
						'@media (max-width:1500px)': {
							fontSize: '2.988rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '2.588rem',
						},
					},
					h1: {
						fontSize: '71px',
						'@media (max-width:1500px)': {
							fontSize: '4.188rem',
						},
						'@media (max-width:1366px)': {
							fontSize: '3.588rem',
						},
					},
				};

			default:
				break;
		}
	};
	return {
		palette: {
			mode: mode,
			...(mode === 'dark'
				? {
						graph: graphColorsApp,
						primary: {
							dark: secondaryColorApp,
							main: primaryColorApp,
							light: colorTokens.primary[800],
						},
						secondary: {
							dark: colorTokens.grey[400],
							main: colorTokens.grey[100],
							light: colorTokens.primary[800],
						},
						info: {
							dark: secondaryColorApp,
							main: colorTokens.grey[200],
							light: colorTokens.primary[800],
						},
						neutral: {
							dark: colorTokens.grey[950],
							// main: colorTokens.grey[500],
							main: colorTokens.grey[650],
							mediumMain: colorTokens.grey[300],
							medium: colorTokens.grey[400],
							light: colorTokens.grey[700],
						},
						background: {
							default: colorTokens.grey[900], // default black  - not exactly black
							alt: colorTokens.grey[800], // greyish black for grids / boxes
							main: colorTokens.grey[950], //  dark color
							mediumMain: colorTokens.grey[650],
							light: colorTokens.grey[700], // light greyish - graphs / cards bg
							dark: colorTokens.grey[1000], // pure black color
						},
						text: {
							primary: colorTokens.grey[0],
							tertiary: primaryColorApp,
							fourth: secondaryColorApp,
						},
						// main app colors
						outerAppStyle: {
							backgroundColor: colorTokens.grey[950],
						},
						dashboardRedBoxStyle: {
							color: '#cf14o8',
						},
						dashboardGreenBoxStyle: {
							color: '#1ba150',
						},
						// for calender in filters reports -:
						reactDateTimePickerStyle: {
							backgroundColor: colorTokens.grey[950],
							color: colorTokens.grey[0],
						},
						borderColorAbm: {
							borderColor: '2px solid  #30363D',
						},
						// in governance -:
						channelAndAttributeBoxesStyle: {
							backgroundColor: colorTokens.grey[800],
							color: colorTokens.grey[0],
							borderBottom: `1px solid ${colorTokens.grey[650]} `,
						},
						// in crm configuration - pink boxes in stepper
						configurationPinkBoxStyle: {
							backgroundColor: 'inherit',
							color: colorTokens.grey[1000],
						},
						// rules box - background
						ruleBoxStyle: {
							backgroundColor: 'inherit',
						},
						// filter calender style in dark mode
						reactFilterCalenderStyle: {
							backgroundColor: colorTokens.grey[800],
							color: colorTokens.grey[0],
							border: `1px solid ${primaryColorApp}`,
						},
						// common page heading style - navbar
						commonPageHeadingNavbarStyle: {
							backgroundColor: colorTokens.grey[800],
							borderBottom: `1px solid ${colorTokens.grey[650]}`,
							boxShadow: 'none',
						},
						// custom waterfall in channel manager -{}
						customWaterfallColors: {
							defaultChannelsDot: {
								backgroundColor: colorTokens.others[10],
							},
						},
				  }
				: {
						graph: graphColorsApp,
						primary: {
							dark: secondaryColorApp,
							main: primaryColorApp,
							light: colorTokens.primary[50],
						},
						secondary: {
							dark: colorTokens.grey[400],
							main: primaryColorApp,
							light: colorTokens.primary[800],
						},
						info: {
							dark: secondaryColorApp,
							main: colorTokens.grey[500],
							light: colorTokens.primary[800],
						},
						neutral: {
							dark: colorTokens.grey[0],
							main: colorTokens.grey[750],
							mediumMain: colorTokens.grey[400],
							medium: colorTokens.grey[300],
							light: colorTokens.grey[50],
						},
						background: {
							default: colorTokens.grey[10],
							alt: colorTokens.grey[0],
							main: colorTokens.grey[0],
							mediumMain: colorTokens.grey[110],
							light: colorTokens.grey[0],
							dark: colorTokens.grey[0], // pure white color
						},
						text: {
							primary: colorTokens.grey[1000],
							tertiary: primaryColorApp,
							fourth: secondaryColorApp,
						},
						// main app colors
						outerAppStyle: {
							backgroundColor: colorTokens.grey[10],
						},
						dashboardRedBoxStyle: {
							color: '#cf1408',
						},
						dashboardGreenBoxStyle: {
							color: '#2a8063',
						},
						// for calender in filters reports -:
						reactDateTimePickerStyle: {
							backgroundColor: colorTokens.grey[0],
							color: colorTokens.grey[950],
						},
						borderColorAbm: {
							borderColor: '2px solid #DCDFE3',
						},
						// in governance - :
						channelAndAttributeBoxesStyle: {
							backgroundColor: secondaryColorApp,
							color: colorTokens.grey[1000],
							borderBottom: `solid .4px #eeeded`,
						},
						// in crm configuration - pink boxes in stepper
						configurationPinkBoxStyle: {
							backgroundColor: colorTokens.primary[100],
							color: colorTokens.grey[1000],
						},
						ruleBoxStyle: {
							backgroundColor: colorTokens.primary[100],
						},
						// filter calender style in light mode
						reactFilterCalenderStyle: {
							backgroundColor: colorTokens.grey[0],
							color: colorTokens.grey[1000],
							// border: `1px solid ${primaryColorApp}`
						},
						// common page heading style - navbar in light mode
						commonPageHeadingNavbarStyle: {
							backgroundColor: colorTokens.grey[0],
							borderBottom: `none`,
							boxShadow: `rgb(100 100 111 / 20%) 0px 7px 10px 0px`,
						},
						// custom waterfall in channel manager -{}
						customWaterfallColors: {
							defaultChannelsDot: {
								backgroundColor: colorTokens.others[10],
							},
						},
				  }),
		},
		typography: {
			fontFamily: 'Lato',
			tablefont: {
				rowFontSize: '1.063rem',
				headFontSize: '1.2rem',
				cellFontSize: '.938rem',
			},
			tabPerformance: {
				fontSize: '.875rem',
			},
			sidebarfont: {
				headingFontSize: '1rem',
				subHeadingFontSize: '.938rem',
			},
			breadCrum: '.95rem',
			barGraph: {
				titleFont: '1.375rem',
				pieFont: '.875rem',
				legendFont: '1rem',
				noDataFont: '.9375rem',
			},
			sourceCard: '.8438rem',
			dashboardFont: '1.25rem',
			dialogTitle: '1.5625rem',
			comparisonDashboard: '2.5rem',
			funnelText: '1.5rem',
			globalAttributes: '1.125rem',
			numPercentage: '1.875rem',
			titleDialog: '1.75rem',
			loginFont: '10px',
			channelFont: '.9rem',
			socialMedia: '2.375rem',
			calenderFont: {
				font: '0.87rem',
				compareFont: '.76rem',
			},
			h6: {
				fontSize: '18px',
				'@media (max-width:1500px)': {
					fontSize: '1rem',
				},
				'@media (max-width:1366px)': {
					fontSize: '.9rem',
				},
			},
			h5: {
				fontSize: '24px',
				fontWeight: 600,
				'@media (max-width:1500px)': {
					fontSize: '20px',
				},
				'@media (max-width:1366px)': {
					fontSize: '1rem',
				},
			},
			h4: {
				fontSize: '28px',
				fontWeight: 600,
				'@media (max-width:1500px)': {
					fontSize: '24px',
				},
				'@media (max-width:1366px)': {
					fontSize: '20px',
				},
			},
			h3: {
				fontSize: '2rem',
				fontWeight: 600,
				'@media (max-width:1500px)': {
					fontSize: '1.6rem',
				},
				'@media (max-width:1366px)': {
					fontSize: '1.4rem',
				},
			},
			h2: {
				fontSize: '45px',
				'@media (max-width:1500px)': {
					fontSize: '2.8rem',
				},
				'@media (max-width:1366px)': {
					fontSize: '2.4rem',
				},
			},
			h1: {
				fontSize: '68px',
				'@media (max-width:1500px)': {
					fontSize: '4rem',
				},
				'@media (max-width:1366px)': {
					fontSize: '3.4rem',
				},
			},
		},
	};
};
