import SwapVertIcon from '@mui/icons-material/SwapVert';
import './SwitchToDiggML.scss';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import ToolTipOnText from '../../components/Tooltip/Tooltip';
// import SwitchToAI from './../../assets/Images/SwitchToAI.svg';
import SwitchToAI from './../../assets/Images/SwitchToAI';
import { IconButton } from '@mui/material';

const SwitchToDiggML = (props: any) => {
	const theme: any = useTheme();
	const currentEnv = location.href;
	const beta = currentEnv.includes('beta');
	const url = window.location.origin;

	const SwitchToDigg = () => {
		let currentUrl = url;
		if (location.hostname == 'app.phlogic.com') {
			currentUrl = 'https://ai.phlogic.com/login';
		} else {
			currentUrl = url.replace('.com', '.ai') + '/login';
		}
		//let currentUrl = url.replace('.com', '.ai') + '/login';
		if (process.env.REACT_APP_KEY_CLOAK_URL) {
			const fullUrl =
				process.env.REACT_APP_KEY_CLOAK_URL +
				process.env.REACT_APP_REALM_REDIRECT +
				'/protocol/openid-connect/auth?response_type=code&client_id=' +
				process.env.REACT_APP_KEY_CLOAK_CLIENT_ID +
				'&redirect_uri=' +
				currentUrl;

			window.open(fullUrl, '_blank');
		}
	};
	const data = {
		fill: theme.palette.text.tertiary,
		width: '20px',
		height: '20px',
	};
	return (
		<>
			<div
				style={{
					left: props.left || '3rem',
					height: '48px',
					width: '48px',
				}}
				className='switchAccount'
			>
				<div
					style={{
						height: '40px',
						width: '40px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<ToolTipOnText title='Switch to Digg AI'>
						{/* <img
							src={SwitchToAI}
							height='20px'
							width='20px'
							style={{ fill: 'red', stroke: 'red' }}
							onClick={() => {
								SwitchToDigg();
							}}
						/> */}
						<IconButton
							onClick={() => {
								SwitchToDigg();
							}}
						>
							<SwitchToAI data={data} />
						</IconButton>
					</ToolTipOnText>
				</div>
			</div>
		</>
	);
};

export default SwitchToDiggML;
